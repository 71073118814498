import API from '@/service/admin-service'
let getToken = ()=>{ return localStorage.getItem('userAccessToken') }
export default {
    async createComponent(body){
        try {
            var res = await API.postAdminApi('api/admin/component/create',{ userid : getToken()}, body);
            return Promise.resolve(res)
        } catch(err){
            return Promise.reject(err)
        }
    },
    async getAllComponent(params){
        try {
            var res = await API.getAdminApi('api/admin/component/getall', { userid : getToken()},params);
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject(err)    
        }
    }
   
}