<template>
    <div class="card">
        <div class="row mx-0">
            <div class="col-md-4">
                <div class="input-box">
                    <label for="" class="labels">Component Name</label>
                    <InputText type="text" toggleMask v-model="component.name" @change="setTemplateType()" placeholder="Enter component name here"/>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-box">
                    <label for="" class="labels">Icon</label>
                    <div class="icon-code">
                        <span class="example-box"><i style="font-family: 'FontAwesome'">{{String.fromCharCode('0x'+component.icon)}}</i></span>
                        <InputText type="text" v-model="component.icon"  placeholder="Enter unicode of icon"/>
                    </div>
                    <a href="https://fontawesome.com/" target="_blank" class="text-right d-block"><i class="fas fa-external-link-alt mr-2"></i>Sample Icon</a>  
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-box">
                    <label for="" class="labels">Background Color</label>
                    <div class="icon-code">
                        <InputText type="text" toggleMask v-model='component.color' placeholder="Select Background Color"/>
                        <label for="colorPicker" class="example-box"><i class="fas fa-eye-dropper"></i><input type="color" id="colorPicker" v-model="component.color"></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="border-div">
            <h3>Child Components</h3>
            <Button @click="component.child.push({name: '', childs: []})" label="Add New Child" icon="fas fa-plus" iconPos="right" class="p-button-outlined p-button-info"/>
        </div>
       <div class="px-3">
            <div class="row mx-0 heading">
            <div class="col-md-8 col-7 border-right">
                <span>Component Name</span>
            </div>
            <div class="col-md-4 col-5">
                <span>Action</span>
            </div>
        </div>
        <div class="row mx-0 p-0 heading border-top-0" v-for="(c, i) in component.child" :key="c">
           <div class="col-12 px-0">
                <div class="row mx-0 custom-border">
                    <div class="col-md-8 col-7 py-2 border-right">
                            <InputText v-model="c.name" type="text" toggleMask placeholder="Child component name"/>
                    </div>
                    <div class="col-md-4 col-5 py-2">
                        <div class="d-flex align-items-center">
                            <Button @click="component.child.splice(i, 1)" icon="pi pi-times" class="p-button-outlined p-button-danger" />
                            <Button icon="fas fa-caret-down"  class="p-button-outlined p-button-info collapsed mr-0 drop-btn" data-toggle="collapse" :data-target="'#CHILD_'+(i+1)" />
                        </div>
                    </div>
               </div>
           </div>
            <div class="col-md-12 px-0 collapse" :id="'CHILD_'+(i+1)">                
                <div class="row mx-0 border-top" style=" background: #f0f4f7;">
                    <div class="col-md-12  pb-3">                                    
                        <div class="border-div px-0">
                            <h3>Grand Child Components</h3>
                            <Button @click="addGChild(i); errorMsg={}" label="Add New Grand Child" icon="fas fa-plus" iconPos="right" class="p-button-outlined p-button-info mr-0"/>
                        </div>
                        <table class="table table-bordered table-light ">
                            <thead>
                                <tr>
                                    <th scope="col">Component Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Documentation</th>
                                    <th scope="col">Properties</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(gc, j) in c.childs" :key="gc">
                                    <td scope="col" data-label="Component Name">{{gc.name}}</td>
                                    <td scope="col" data-label="Description">{{gc.description}}</td>
                                    <td scope="col" data-label="Documentation">{{gc.documentation}}</td>
                                    <td scope="col" data-label="Properties">{{gc.properties}}</td>
                                    <td scope="col" data-label="Action" class="d-flex align-items-center">
                                        <Button @click="c.childs.splice(j, 1)"  icon="pi pi-times" class="p-button-outlined mr-2 p-button-danger" />
                                        <Button @click="openEditModal(gc, j, i)"  icon="fas fa-edit" class="p-button-outlined p-button-info" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <label class="text-danger" v-if="errorMsg.child != '' ">{{errorMsg.child}}</label>
        </div>
       </div>
    </div>
    <Dialog header="Create Grand Child" :modal='true' v-model:visible="displayAddGchild" style="max-width:800px; width: 100%;">
        <div class="row">
            <div class="col-md-6 modal-input" >
                <div class="input-row">
                <InputText type="text" toggleMask v-model="newGchildObj.name" placeholder="Name"/>
                <label class="text-danger" v-if="errorMsg.name != '' ">{{errorMsg.name}}</label>
                </div>
            </div>
            <div class="col-md-6 modal-input" >
                <div class="input-row">
                <InputText type="text" toggleMask v-model="newGchildObj.description" placeholder="Description"/>
                <label class="text-danger" v-if="errorMsg.description != '' ">{{errorMsg.description}}</label>
                </div>
            </div>
            <div class="col-md-12 modal-input" >
                <div class="input-row">
                <InputText type="text" toggleMask v-model="newGchildObj.documentation" placeholder="Documentation"/>
                <label class="text-danger" v-if="errorMsg.documentation != '' ">{{errorMsg.documentation}}</label>

                </div>
            </div>
            <div class="col-md-12 modal-input" >
                <ckeditor :editor="editor" v-model="newGchildObj.properties" :config="editorConfig" />
                <label class="text-danger" v-if="errorMsg.properties != '' ">{{errorMsg.properties}}</label>
            </div>
            <div class="col-md-12">
                <div class="border-div d-flex ">
                    <h3 class="mb-0">Properties</h3>
                    <Button @click="newGchildObj.ports.push({name: '', type: 'INPUT'}); errorMsg.main= ''" label="Add New"  icon="fas fa-plus"  iconPos="left"  class="p-button-outlined p-button-info" />
                </div>
            </div>
            <div class="table-div w-100 p-2" data-v-60e536fa="">
                <table class="table table-bordered" data-v-60e536fa="">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Type</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(p, pi) in newGchildObj.ports" :key="p">
                            <td scope="row" data-label="Name">
                                <input class="p-inputtext p-component w-100 mt-md-0 mt-1" v-model="p.name" type="text" togglemask="" placeholder="Port name">
                            </td>
                            <td scope="row" data-label="Type">
                                <Dropdown  v-model="p.type" :options="['INPUT', 'OUTPUT']" class="w-100 mt-md-0 mt-1" />
                            </td>
                            <td scope="row" data-label="action" class="d-flex align-items-center">
                                <Button @click="newGchildObj.ports.splice(pi, 1)" icon="pi pi-times" class="p-button-danger p-button-outlined px-4" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <label class="text-danger" v-if="errorMsg.ports != '' ">{{errorMsg.ports}}</label>
        </div>
        <template #footer>
            <Button label="Cancle"  @click="displayAddGchild = false;" class="p-button-text p-button-info"/>
            <Button label="Save"  @click="saveGchild()" autofocus class="p-button-info" />
        </template>
    </Dialog>
    <label class="text-danger" v-if="errorMsg.main != '' ">{{errorMsg.main}}</label>
    <div class="text-right py-2">
        <Button @click="createComponent()" class='p-button-info px-4' >Save</Button>
    </div>
    <div class="loader" id="propertiesLoader" v-if = "loader">
        <ProgressSpinner style="width:50px;height:50px" />
    </div>
</template>

<script>
import methods from './add-components'
import cm from 'maksof-common'
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default {
        name: "ADD COMPONENT",
        components:{
            ckeditor: CKEditor.component
        },
        data(){
            return{  
                loader : false,
                toAddChildIndex: -1,
                toUpdateIndex: -1,
                displayAddGchild: false,
                newGchildObj: { ports: [] },
                component : {
                    templateType    : '',
                    name            : '',
                    color           : '',
                    icon            : '',
                    child           : []
                },
                errorMsg : { },
                editor: ClassicEditor,
                editorConfig: { }              
            }
        },
        methods:{
            resetGchild() {
                this.newGchildObj = {ports: []};
                this.toAddChildIndex = -1;
                this.toUpdateIndex = -1;
            },
            setTemplateType() {
                var str = Math.random().toString(36).substring(10).toUpperCase();
                this.component.templateType = this.component.name.toUpperCase()+"_"+str;
            },      
            addGChild(index) {
                this.resetGchild();
                this.toAddChildIndex = index;
                this.displayAddGchild = true;
            },
            openEditModal(gchild, index, childIndex) {
                this.resetGchild();
                this.toAddChildIndex = childIndex;
                this.toUpdateIndex = index;
                this.newGchildObj = gchild;
                this.displayAddGchild = true;
            },
            saveGchild() {
                let inputP = [];
                let outputP = [];
                if('name' in this.newGchildObj || !cm.isEmpty(this.newGchildObj.name)) {
                    this.errorMsg.name = ''
                    if('description' in this.newGchildObj && !cm.isEmpty(this.newGchildObj.description) ){
                        this.errorMsg.description = ''
                        if('documentation' in this.newGchildObj && !cm.isEmpty(this.newGchildObj.documentation) ) {
                            this.errorMsg.documentation = '';
                            if ('properties' in this.newGchildObj && !cm.isEmpty(this.newGchildObj.properties) ){
                                this.errorMsg.properties = '';
                                if (this.newGchildObj.ports.length > 0 ){
                                    this.errorMsg.port= '';
                                    this.newGchildObj.ports.forEach(function(p){

                                        if(!cm.isEmpty(p.name)){
                                            if(p.type === "INPUT") inputP.push({name: p.name});
                                            else outputP.push({name: p.name});
                                        }   
                                    })
                                    this.newGchildObj.input = inputP;
                                    this.newGchildObj.output = outputP;
                                    console.log(this.toUpdateIndex);
                                    if(this.toUpdateIndex != -1) this.component.child[this.toAddChildIndex].childs[this.toUpdateIndex] = this.newGchildObj;
                                    else this.component.child[this.toAddChildIndex].childs.push(this.newGchildObj);
                                    this.displayAddGchild = false;
                                    this.resetGchild();
                               } else this.errorMsg.ports= 'port is missing';
                            } else this.errorMsg.properties = 'properties is missing';
                        } else this.errorMsg.documentation = 'documentation is missing';
                    } else this.errorMsg.description = 'description is missing';
                }else this.errorMsg.name = 'Name is missing' ;
            },
            async createComponent(){
                this.loader = true
                if(!cm.isEmpty(this.component.name) && !cm.isEmpty(this.component.color) && !cm.isObjEmpty(this.component.icon) &&!cm.isArrayEmpty(this.component.child)){
                    this.errorMsg.main = '';
                    var isEmpty = false;
                    this.component.child.forEach(function(c){
                        if(cm.isEmpty(c.name) || c.childs.length == 0) isEmpty = true;                        
                    });
                    if(isEmpty) this.errorMsg.child = 'Please insert atleast one Grand child';
                    else {
                        this.errorMsg={};
                        let res = await methods.createComponent(this.component);
                        if(res.status == 'OK'){
                            this.loader = false;
                            alert(res.message)
                        }else {
                            this.loader = false;
                            alert(res.message)
                        }
                    }
                }else this.errorMsg.main = 'Please fill all the fields';
            },
        }
    }
</script>

<style lang="scss" scoped>
@import 'add-components.scss';
</style>