import axios from 'axios';
import config from './service-config'


let postAdminApi = async (url,headers,data,) => {
    headers['x-api-key'] = config.apikey;
    try{
        let res = await axios.post(config.baseUrl()+url,data,{ headers : headers, })
        return Promise.resolve(res.data);
    } catch(err){
        return Promise.reject(err)
    }
}

let getAdminApi = async (url,headers,params) => {
    headers['x-api-key'] = config.apikey;
    try{
        let res = await axios.get(config.baseUrl()+url,{ headers : headers,  params : params })
        return Promise.resolve(res.data);
    } catch(err){
        return Promise.reject(err)
    }
}

export  default { postAdminApi, getAdminApi };
